/*** COLOR CODES 3 **/
/**
 * arches - Global style system focused on utility classes
 * @version v4.1.4x
 * @link https://github.com/ACC-Style/Arches/
 */
/**  Build Comment: including file /setup/__preheader.scss  **/
/** SCSS DOC: _preheader.scss **/
/** SCSS DOC: _helpers.scss **/
/** SCSS DOC: _utility-css.vars.scss **/
/**  Build Comment: This Style Sheet should only be used as an augmenting stylesheet  **/
/**  Build Comment: Set Active Class  **/
/**  No Brands Are Used for small project that need to be included with a larger base style sheet.    *********************************/
/**  Colors  **/
/** SCSS DOC: __brand.none.scss **/
/** SCSS DOC: _colors.scss **/
/** Setting link color variables in scss **/
/*

Link Colors: Light

Link colors of a lighter shade in all of its states, default, hover, visited. Typically used to enhance visibility on dark backgrounds.

$link-color-dark			- #126657; Link Color dark			
$link-color-dark--hover	- #051f1a; Link Color dark--hover	
$link-color-dark--visited	- #42665f; Link Color dark--visited	

Weight:2

Styleguide DesignBase.Colors.LinksLight
 */
/*

Link Colors

Link colors  in all of its states, default, hover, visited.


$link-color-light			- #8cebda; Link Color light			
$link-color-light--hover	- #e8fbf8; Link Color light--hover	
$link-color-light--visited	- #84afa7; Link Color light--visited	
$link-color-dark			- #126657; Link Color dark			
$link-color-dark--hover	- #051f1a; Link Color dark--hover	
$link-color-dark--visited	- #42665f; Link Color dark--visited	

Weight:1

Styleguide DesignBase.Colors.Links
 */
/**  Build Comment: Start with No Branding 4.1.4x This is for an augmenting color sheets that don't require a brand  **/
/** SCSS DOC: __var.output.scss **/
/** This needs to run later then brand **/
/** SCSS DOC: _setup.none.scss **/
/**  Build Comment: pathway Color Stylesheet  **/
.bg_invasive {
  background-color: #c8b02f !important;
}

.bg_invasive-0 {
  background-color: #c8b02f !important;
}

.bg_invasive-1 {
  background-color: #d2bb3f !important;
}

.bg_invasive-2 {
  background-color: #d9c65f !important;
}

.bg_invasive-3 {
  background-color: #e6d995 !important;
}

.bg_invasive-4 {
  background-color: #f2ecca !important;
}

.bg_invasive-5 {
  background-color: #faf7ea !important;
}

.bg_invasive-n1 {
  background-color: #aa9628 !important;
}

.bg_invasive-n2 {
  background-color: #8c7b21 !important;
}

.bg_invasive-n3 {
  background-color: #645818 !important;
}

.bg_invasive-n4 {
  background-color: #3c350e !important;
}

.bg_invasive-n5 {
  background-color: #1e1a07 !important;
}

.c_invasive {
  color: #c8b02f !important;
}

.c_invasive-0 {
  color: #c8b02f !important;
}

.c_invasive-1 {
  color: #d2bb3f !important;
}

.c_invasive-2 {
  color: #d9c65f !important;
}

.c_invasive-3 {
  color: #e6d995 !important;
}

.c_invasive-4 {
  color: #f2ecca !important;
}

.c_invasive-5 {
  color: #faf7ea !important;
}

.c_invasive-n1 {
  color: #aa9628 !important;
}

.c_invasive-n2 {
  color: #8c7b21 !important;
}

.c_invasive-n3 {
  color: #645818 !important;
}

.c_invasive-n4 {
  color: #3c350e !important;
}

.c_invasive-n5 {
  color: #1e1a07 !important;
}

.br_invasive {
  border-color: #c8b02f !important;
}

.br_invasive-0 {
  border-color: #c8b02f !important;
}

.br_invasive-1 {
  border-color: #d2bb3f !important;
}

.br_invasive-2 {
  border-color: #d9c65f !important;
}

.br_invasive-3 {
  border-color: #e6d995 !important;
}

.br_invasive-4 {
  border-color: #f2ecca !important;
}

.br_invasive-5 {
  border-color: #faf7ea !important;
}

.br_invasive-n1 {
  border-color: #aa9628 !important;
}

.br_invasive-n2 {
  border-color: #8c7b21 !important;
}

.br_invasive-n3 {
  border-color: #645818 !important;
}

.br_invasive-n4 {
  border-color: #3c350e !important;
}

.br_invasive-n5 {
  border-color: #1e1a07 !important;
}

.bg_congenital {
  background-color: #fdb918 !important;
}

.bg_congenital-0 {
  background-color: #fdb918 !important;
}

.bg_congenital-1 {
  background-color: #fdc02f !important;
}

.bg_congenital-2 {
  background-color: #fecb52 !important;
}

.bg_congenital-3 {
  background-color: #fedc8c !important;
}

.bg_congenital-4 {
  background-color: #ffeec5 !important;
}

.bg_congenital-5 {
  background-color: #fff8e8 !important;
}

.bg_congenital-n1 {
  background-color: #e9a502 !important;
}

.bg_congenital-n2 {
  background-color: #c08802 !important;
}

.bg_congenital-n3 {
  background-color: #896101 !important;
}

.bg_congenital-n4 {
  background-color: #523a01 !important;
}

.bg_congenital-n5 {
  background-color: #291d00 !important;
}

.c_congenital {
  color: #fdb918 !important;
}

.c_congenital-0 {
  color: #fdb918 !important;
}

.c_congenital-1 {
  color: #fdc02f !important;
}

.c_congenital-2 {
  color: #fecb52 !important;
}

.c_congenital-3 {
  color: #fedc8c !important;
}

.c_congenital-4 {
  color: #ffeec5 !important;
}

.c_congenital-5 {
  color: #fff8e8 !important;
}

.c_congenital-n1 {
  color: #e9a502 !important;
}

.c_congenital-n2 {
  color: #c08802 !important;
}

.c_congenital-n3 {
  color: #896101 !important;
}

.c_congenital-n4 {
  color: #523a01 !important;
}

.c_congenital-n5 {
  color: #291d00 !important;
}

.br_congenital {
  border-color: #fdb918 !important;
}

.br_congenital-0 {
  border-color: #fdb918 !important;
}

.br_congenital-1 {
  border-color: #fdc02f !important;
}

.br_congenital-2 {
  border-color: #fecb52 !important;
}

.br_congenital-3 {
  border-color: #fedc8c !important;
}

.br_congenital-4 {
  border-color: #ffeec5 !important;
}

.br_congenital-5 {
  border-color: #fff8e8 !important;
}

.br_congenital-n1 {
  border-color: #e9a502 !important;
}

.br_congenital-n2 {
  border-color: #c08802 !important;
}

.br_congenital-n3 {
  border-color: #896101 !important;
}

.br_congenital-n4 {
  border-color: #523a01 !important;
}

.br_congenital-n5 {
  border-color: #291d00 !important;
}

.bg_noninvasive {
  background-color: #947b33 !important;
}

.bg_noninvasive-0 {
  background-color: #947b33 !important;
}

.bg_noninvasive-1 {
  background-color: #ab8e3b !important;
}

.bg_noninvasive-2 {
  background-color: #c3a652 !important;
}

.bg_noninvasive-3 {
  background-color: #d7c48b !important;
}

.bg_noninvasive-4 {
  background-color: #ebe1c5 !important;
}

.bg_noninvasive-5 {
  background-color: #f7f3e8 !important;
}

.bg_noninvasive-n1 {
  background-color: #7e692b !important;
}

.bg_noninvasive-n2 {
  background-color: #685624 !important;
}

.bg_noninvasive-n3 {
  background-color: #4a3e1a !important;
}

.bg_noninvasive-n4 {
  background-color: #2c250f !important;
}

.bg_noninvasive-n5 {
  background-color: #161208 !important;
}

.c_noninvasive {
  color: #947b33 !important;
}

.c_noninvasive-0 {
  color: #947b33 !important;
}

.c_noninvasive-1 {
  color: #ab8e3b !important;
}

.c_noninvasive-2 {
  color: #c3a652 !important;
}

.c_noninvasive-3 {
  color: #d7c48b !important;
}

.c_noninvasive-4 {
  color: #ebe1c5 !important;
}

.c_noninvasive-5 {
  color: #f7f3e8 !important;
}

.c_noninvasive-n1 {
  color: #7e692b !important;
}

.c_noninvasive-n2 {
  color: #685624 !important;
}

.c_noninvasive-n3 {
  color: #4a3e1a !important;
}

.c_noninvasive-n4 {
  color: #2c250f !important;
}

.c_noninvasive-n5 {
  color: #161208 !important;
}

.br_noninvasive {
  border-color: #947b33 !important;
}

.br_noninvasive-0 {
  border-color: #947b33 !important;
}

.br_noninvasive-1 {
  border-color: #ab8e3b !important;
}

.br_noninvasive-2 {
  border-color: #c3a652 !important;
}

.br_noninvasive-3 {
  border-color: #d7c48b !important;
}

.br_noninvasive-4 {
  border-color: #ebe1c5 !important;
}

.br_noninvasive-5 {
  border-color: #f7f3e8 !important;
}

.br_noninvasive-n1 {
  border-color: #7e692b !important;
}

.br_noninvasive-n2 {
  border-color: #685624 !important;
}

.br_noninvasive-n3 {
  border-color: #4a3e1a !important;
}

.br_noninvasive-n4 {
  border-color: #2c250f !important;
}

.br_noninvasive-n5 {
  border-color: #161208 !important;
}

.bg_ecg {
  background-color: #d9691f !important;
}

.bg_ecg-0 {
  background-color: #d9691f !important;
}

.bg_ecg-1 {
  background-color: #e27731 !important;
}

.bg_ecg-2 {
  background-color: #e68e53 !important;
}

.bg_ecg-3 {
  background-color: #efb38c !important;
}

.bg_ecg-4 {
  background-color: #f7d9c6 !important;
}

.bg_ecg-5 {
  background-color: #fcf0e8 !important;
}

.bg_ecg-n1 {
  background-color: #b8591a !important;
}

.bg_ecg-n2 {
  background-color: #984a16 !important;
}

.bg_ecg-n3 {
  background-color: #6d3510 !important;
}

.bg_ecg-n4 {
  background-color: #412009 !important;
}

.bg_ecg-n5 {
  background-color: #211005 !important;
}

.c_ecg {
  color: #d9691f !important;
}

.c_ecg-0 {
  color: #d9691f !important;
}

.c_ecg-1 {
  color: #e27731 !important;
}

.c_ecg-2 {
  color: #e68e53 !important;
}

.c_ecg-3 {
  color: #efb38c !important;
}

.c_ecg-4 {
  color: #f7d9c6 !important;
}

.c_ecg-5 {
  color: #fcf0e8 !important;
}

.c_ecg-n1 {
  color: #b8591a !important;
}

.c_ecg-n2 {
  color: #984a16 !important;
}

.c_ecg-n3 {
  color: #6d3510 !important;
}

.c_ecg-n4 {
  color: #412009 !important;
}

.c_ecg-n5 {
  color: #211005 !important;
}

.br_ecg {
  border-color: #d9691f !important;
}

.br_ecg-0 {
  border-color: #d9691f !important;
}

.br_ecg-1 {
  border-color: #e27731 !important;
}

.br_ecg-2 {
  border-color: #e68e53 !important;
}

.br_ecg-3 {
  border-color: #efb38c !important;
}

.br_ecg-4 {
  border-color: #f7d9c6 !important;
}

.br_ecg-5 {
  border-color: #fcf0e8 !important;
}

.br_ecg-n1 {
  border-color: #b8591a !important;
}

.br_ecg-n2 {
  border-color: #984a16 !important;
}

.br_ecg-n3 {
  border-color: #6d3510 !important;
}

.br_ecg-n4 {
  border-color: #412009 !important;
}

.br_ecg-n5 {
  border-color: #211005 !important;
}

.bg_vascular {
  background-color: #ae171c !important;
}

.bg_vascular-0 {
  background-color: #ae171c !important;
}

.bg_vascular-1 {
  background-color: #ca1b20 !important;
}

.bg_vascular-2 {
  background-color: #e43036 !important;
}

.bg_vascular-3 {
  background-color: #ed7579 !important;
}

.bg_vascular-4 {
  background-color: #f6babc !important;
}

.bg_vascular-5 {
  background-color: #fbe3e4 !important;
}

.bg_vascular-n1 {
  background-color: #941418 !important;
}

.bg_vascular-n2 {
  background-color: #7a1014 !important;
}

.bg_vascular-n3 {
  background-color: #570c0e !important;
}

.bg_vascular-n4 {
  background-color: #340708 !important;
}

.bg_vascular-n5 {
  background-color: #1a0304 !important;
}

.c_vascular {
  color: #ae171c !important;
}

.c_vascular-0 {
  color: #ae171c !important;
}

.c_vascular-1 {
  color: #ca1b20 !important;
}

.c_vascular-2 {
  color: #e43036 !important;
}

.c_vascular-3 {
  color: #ed7579 !important;
}

.c_vascular-4 {
  color: #f6babc !important;
}

.c_vascular-5 {
  color: #fbe3e4 !important;
}

.c_vascular-n1 {
  color: #941418 !important;
}

.c_vascular-n2 {
  color: #7a1014 !important;
}

.c_vascular-n3 {
  color: #570c0e !important;
}

.c_vascular-n4 {
  color: #340708 !important;
}

.c_vascular-n5 {
  color: #1a0304 !important;
}

.br_vascular {
  border-color: #ae171c !important;
}

.br_vascular-0 {
  border-color: #ae171c !important;
}

.br_vascular-1 {
  border-color: #ca1b20 !important;
}

.br_vascular-2 {
  border-color: #e43036 !important;
}

.br_vascular-3 {
  border-color: #ed7579 !important;
}

.br_vascular-4 {
  border-color: #f6babc !important;
}

.br_vascular-5 {
  border-color: #fbe3e4 !important;
}

.br_vascular-n1 {
  border-color: #941418 !important;
}

.br_vascular-n2 {
  border-color: #7a1014 !important;
}

.br_vascular-n3 {
  border-color: #570c0e !important;
}

.br_vascular-n4 {
  border-color: #340708 !important;
}

.br_vascular-n5 {
  border-color: #1a0304 !important;
}

.bg_pulmonary {
  background-color: #d60e7e !important;
}

.bg_pulmonary-0 {
  background-color: #d60e7e !important;
}

.bg_pulmonary-1 {
  background-color: #ef118d !important;
}

.bg_pulmonary-2 {
  background-color: #f238a0 !important;
}

.bg_pulmonary-3 {
  background-color: #f67bc0 !important;
}

.bg_pulmonary-4 {
  background-color: #fbbddf !important;
}

.bg_pulmonary-5 {
  background-color: #fde5f2 !important;
}

.bg_pulmonary-n1 {
  background-color: #b60c6b !important;
}

.bg_pulmonary-n2 {
  background-color: #960a58 !important;
}

.bg_pulmonary-n3 {
  background-color: #6b073f !important;
}

.bg_pulmonary-n4 {
  background-color: #400426 !important;
}

.bg_pulmonary-n5 {
  background-color: #200213 !important;
}

.c_pulmonary {
  color: #d60e7e !important;
}

.c_pulmonary-0 {
  color: #d60e7e !important;
}

.c_pulmonary-1 {
  color: #ef118d !important;
}

.c_pulmonary-2 {
  color: #f238a0 !important;
}

.c_pulmonary-3 {
  color: #f67bc0 !important;
}

.c_pulmonary-4 {
  color: #fbbddf !important;
}

.c_pulmonary-5 {
  color: #fde5f2 !important;
}

.c_pulmonary-n1 {
  color: #b60c6b !important;
}

.c_pulmonary-n2 {
  color: #960a58 !important;
}

.c_pulmonary-n3 {
  color: #6b073f !important;
}

.c_pulmonary-n4 {
  color: #400426 !important;
}

.c_pulmonary-n5 {
  color: #200213 !important;
}

.br_pulmonary {
  border-color: #d60e7e !important;
}

.br_pulmonary-0 {
  border-color: #d60e7e !important;
}

.br_pulmonary-1 {
  border-color: #ef118d !important;
}

.br_pulmonary-2 {
  border-color: #f238a0 !important;
}

.br_pulmonary-3 {
  border-color: #f67bc0 !important;
}

.br_pulmonary-4 {
  border-color: #fbbddf !important;
}

.br_pulmonary-5 {
  border-color: #fde5f2 !important;
}

.br_pulmonary-n1 {
  border-color: #b60c6b !important;
}

.br_pulmonary-n2 {
  border-color: #960a58 !important;
}

.br_pulmonary-n3 {
  border-color: #6b073f !important;
}

.br_pulmonary-n4 {
  border-color: #400426 !important;
}

.br_pulmonary-n5 {
  border-color: #200213 !important;
}

.bg_prevention {
  background-color: #a01d51 !important;
}

.bg_prevention-0 {
  background-color: #a01d51 !important;
}

.bg_prevention-1 {
  background-color: #bb225f !important;
}

.bg_prevention-2 {
  background-color: #da3375 !important;
}

.bg_prevention-3 {
  background-color: #e677a3 !important;
}

.bg_prevention-4 {
  background-color: #f3bbd1 !important;
}

.bg_prevention-5 {
  background-color: #fae4ed !important;
}

.bg_prevention-n1 {
  background-color: #881945 !important;
}

.bg_prevention-n2 {
  background-color: #701439 !important;
}

.bg_prevention-n3 {
  background-color: #500f29 !important;
}

.bg_prevention-n4 {
  background-color: #300918 !important;
}

.bg_prevention-n5 {
  background-color: #18040c !important;
}

.c_prevention {
  color: #a01d51 !important;
}

.c_prevention-0 {
  color: #a01d51 !important;
}

.c_prevention-1 {
  color: #bb225f !important;
}

.c_prevention-2 {
  color: #da3375 !important;
}

.c_prevention-3 {
  color: #e677a3 !important;
}

.c_prevention-4 {
  color: #f3bbd1 !important;
}

.c_prevention-5 {
  color: #fae4ed !important;
}

.c_prevention-n1 {
  color: #881945 !important;
}

.c_prevention-n2 {
  color: #701439 !important;
}

.c_prevention-n3 {
  color: #500f29 !important;
}

.c_prevention-n4 {
  color: #300918 !important;
}

.c_prevention-n5 {
  color: #18040c !important;
}

.br_prevention {
  border-color: #a01d51 !important;
}

.br_prevention-0 {
  border-color: #a01d51 !important;
}

.br_prevention-1 {
  border-color: #bb225f !important;
}

.br_prevention-2 {
  border-color: #da3375 !important;
}

.br_prevention-3 {
  border-color: #e677a3 !important;
}

.br_prevention-4 {
  border-color: #f3bbd1 !important;
}

.br_prevention-5 {
  border-color: #fae4ed !important;
}

.br_prevention-n1 {
  border-color: #881945 !important;
}

.br_prevention-n2 {
  border-color: #701439 !important;
}

.br_prevention-n3 {
  border-color: #500f29 !important;
}

.br_prevention-n4 {
  border-color: #300918 !important;
}

.br_prevention-n5 {
  border-color: #18040c !important;
}

.bg_hf {
  background-color: #410d49 !important;
}

.bg_hf-0 {
  background-color: #410d49 !important;
}

.bg_hf-1 {
  background-color: #61136d !important;
}

.bg_hf-2 {
  background-color: #911da3 !important;
}

.bg_hf-3 {
  background-color: #cb4bdf !important;
}

.bg_hf-4 {
  background-color: #e5a5ef !important;
}

.bg_hf-5 {
  background-color: #f5dbf9 !important;
}

.bg_hf-n1 {
  background-color: #370b3e !important;
}

.bg_hf-n2 {
  background-color: #2e0933 !important;
}

.bg_hf-n3 {
  background-color: #210725 !important;
}

.bg_hf-n4 {
  background-color: #140416 !important;
}

.bg_hf-n5 {
  background-color: #0a020b !important;
}

.c_hf {
  color: #410d49 !important;
}

.c_hf-0 {
  color: #410d49 !important;
}

.c_hf-1 {
  color: #61136d !important;
}

.c_hf-2 {
  color: #911da3 !important;
}

.c_hf-3 {
  color: #cb4bdf !important;
}

.c_hf-4 {
  color: #e5a5ef !important;
}

.c_hf-5 {
  color: #f5dbf9 !important;
}

.c_hf-n1 {
  color: #370b3e !important;
}

.c_hf-n2 {
  color: #2e0933 !important;
}

.c_hf-n3 {
  color: #210725 !important;
}

.c_hf-n4 {
  color: #140416 !important;
}

.c_hf-n5 {
  color: #0a020b !important;
}

.br_hf {
  border-color: #410d49 !important;
}

.br_hf-0 {
  border-color: #410d49 !important;
}

.br_hf-1 {
  border-color: #61136d !important;
}

.br_hf-2 {
  border-color: #911da3 !important;
}

.br_hf-3 {
  border-color: #cb4bdf !important;
}

.br_hf-4 {
  border-color: #e5a5ef !important;
}

.br_hf-5 {
  border-color: #f5dbf9 !important;
}

.br_hf-n1 {
  border-color: #370b3e !important;
}

.br_hf-n2 {
  border-color: #2e0933 !important;
}

.br_hf-n3 {
  border-color: #210725 !important;
}

.br_hf-n4 {
  border-color: #140416 !important;
}

.br_hf-n5 {
  border-color: #0a020b !important;
}

.bg_valvular {
  background-color: #4f4190 !important;
}

.bg_valvular-0 {
  background-color: #4f4190 !important;
}

.bg_valvular-1 {
  background-color: #5a4aa5 !important;
}

.bg_valvular-2 {
  background-color: #7363b9 !important;
}

.bg_valvular-3 {
  background-color: #a197d0 !important;
}

.bg_valvular-4 {
  background-color: #d0cbe8 !important;
}

.bg_valvular-5 {
  background-color: #eceaf6 !important;
}

.bg_valvular-n1 {
  background-color: #43377a !important;
}

.bg_valvular-n2 {
  background-color: #372e65 !important;
}

.bg_valvular-n3 {
  background-color: #282148 !important;
}

.bg_valvular-n4 {
  background-color: #18142b !important;
}

.bg_valvular-n5 {
  background-color: #0c0a16 !important;
}

.c_valvular {
  color: #4f4190 !important;
}

.c_valvular-0 {
  color: #4f4190 !important;
}

.c_valvular-1 {
  color: #5a4aa5 !important;
}

.c_valvular-2 {
  color: #7363b9 !important;
}

.c_valvular-3 {
  color: #a197d0 !important;
}

.c_valvular-4 {
  color: #d0cbe8 !important;
}

.c_valvular-5 {
  color: #eceaf6 !important;
}

.c_valvular-n1 {
  color: #43377a !important;
}

.c_valvular-n2 {
  color: #372e65 !important;
}

.c_valvular-n3 {
  color: #282148 !important;
}

.c_valvular-n4 {
  color: #18142b !important;
}

.c_valvular-n5 {
  color: #0c0a16 !important;
}

.br_valvular {
  border-color: #4f4190 !important;
}

.br_valvular-0 {
  border-color: #4f4190 !important;
}

.br_valvular-1 {
  border-color: #5a4aa5 !important;
}

.br_valvular-2 {
  border-color: #7363b9 !important;
}

.br_valvular-3 {
  border-color: #a197d0 !important;
}

.br_valvular-4 {
  border-color: #d0cbe8 !important;
}

.br_valvular-5 {
  border-color: #eceaf6 !important;
}

.br_valvular-n1 {
  border-color: #43377a !important;
}

.br_valvular-n2 {
  border-color: #372e65 !important;
}

.br_valvular-n3 {
  border-color: #282148 !important;
}

.br_valvular-n4 {
  border-color: #18142b !important;
}

.br_valvular-n5 {
  border-color: #0c0a16 !important;
}

.bg_arrhythmias {
  background-color: #005b37 !important;
}

.bg_arrhythmias-0 {
  background-color: #005b37 !important;
}

.bg_arrhythmias-1 {
  background-color: #008550 !important;
}

.bg_arrhythmias-2 {
  background-color: #00c476 !important;
}

.bg_arrhythmias-3 {
  background-color: #2effac !important;
}

.bg_arrhythmias-4 {
  background-color: #96ffd6 !important;
}

.bg_arrhythmias-5 {
  background-color: #d5ffee !important;
}

.bg_arrhythmias-n1 {
  background-color: #004d2f !important;
}

.bg_arrhythmias-n2 {
  background-color: #004027 !important;
}

.bg_arrhythmias-n3 {
  background-color: #002e1c !important;
}

.bg_arrhythmias-n4 {
  background-color: #001b11 !important;
}

.bg_arrhythmias-n5 {
  background-color: #000e08 !important;
}

.c_arrhythmias {
  color: #005b37 !important;
}

.c_arrhythmias-0 {
  color: #005b37 !important;
}

.c_arrhythmias-1 {
  color: #008550 !important;
}

.c_arrhythmias-2 {
  color: #00c476 !important;
}

.c_arrhythmias-3 {
  color: #2effac !important;
}

.c_arrhythmias-4 {
  color: #96ffd6 !important;
}

.c_arrhythmias-5 {
  color: #d5ffee !important;
}

.c_arrhythmias-n1 {
  color: #004d2f !important;
}

.c_arrhythmias-n2 {
  color: #004027 !important;
}

.c_arrhythmias-n3 {
  color: #002e1c !important;
}

.c_arrhythmias-n4 {
  color: #001b11 !important;
}

.c_arrhythmias-n5 {
  color: #000e08 !important;
}

.br_arrhythmias {
  border-color: #005b37 !important;
}

.br_arrhythmias-0 {
  border-color: #005b37 !important;
}

.br_arrhythmias-1 {
  border-color: #008550 !important;
}

.br_arrhythmias-2 {
  border-color: #00c476 !important;
}

.br_arrhythmias-3 {
  border-color: #2effac !important;
}

.br_arrhythmias-4 {
  border-color: #96ffd6 !important;
}

.br_arrhythmias-5 {
  border-color: #d5ffee !important;
}

.br_arrhythmias-n1 {
  border-color: #004d2f !important;
}

.br_arrhythmias-n2 {
  border-color: #004027 !important;
}

.br_arrhythmias-n3 {
  border-color: #002e1c !important;
}

.br_arrhythmias-n4 {
  border-color: #001b11 !important;
}

.br_arrhythmias-n5 {
  border-color: #000e08 !important;
}

.bg_pericardial {
  background-color: #0079ad !important;
}

.bg_pericardial-0 {
  background-color: #0079ad !important;
}

.bg_pericardial-1 {
  background-color: #0091cf !important;
}

.bg_pericardial-2 {
  background-color: #02b3ff !important;
}

.bg_pericardial-3 {
  background-color: #57ccff !important;
}

.bg_pericardial-4 {
  background-color: #abe6ff !important;
}

.bg_pericardial-5 {
  background-color: #ddf5ff !important;
}

.bg_pericardial-n1 {
  background-color: #006793 !important;
}

.bg_pericardial-n2 {
  background-color: #005579 !important;
}

.bg_pericardial-n3 {
  background-color: #003d57 !important;
}

.bg_pericardial-n4 {
  background-color: #002434 !important;
}

.bg_pericardial-n5 {
  background-color: #00121a !important;
}

.c_pericardial {
  color: #0079ad !important;
}

.c_pericardial-0 {
  color: #0079ad !important;
}

.c_pericardial-1 {
  color: #0091cf !important;
}

.c_pericardial-2 {
  color: #02b3ff !important;
}

.c_pericardial-3 {
  color: #57ccff !important;
}

.c_pericardial-4 {
  color: #abe6ff !important;
}

.c_pericardial-5 {
  color: #ddf5ff !important;
}

.c_pericardial-n1 {
  color: #006793 !important;
}

.c_pericardial-n2 {
  color: #005579 !important;
}

.c_pericardial-n3 {
  color: #003d57 !important;
}

.c_pericardial-n4 {
  color: #002434 !important;
}

.c_pericardial-n5 {
  color: #00121a !important;
}

.br_pericardial {
  border-color: #0079ad !important;
}

.br_pericardial-0 {
  border-color: #0079ad !important;
}

.br_pericardial-1 {
  border-color: #0091cf !important;
}

.br_pericardial-2 {
  border-color: #02b3ff !important;
}

.br_pericardial-3 {
  border-color: #57ccff !important;
}

.br_pericardial-4 {
  border-color: #abe6ff !important;
}

.br_pericardial-5 {
  border-color: #ddf5ff !important;
}

.br_pericardial-n1 {
  border-color: #006793 !important;
}

.br_pericardial-n2 {
  border-color: #005579 !important;
}

.br_pericardial-n3 {
  border-color: #003d57 !important;
}

.br_pericardial-n4 {
  border-color: #002434 !important;
}

.br_pericardial-n5 {
  border-color: #00121a !important;
}

.bg_acute {
  background-color: #009974 !important;
}

.bg_acute-0 {
  background-color: #009974 !important;
}

.bg_acute-1 {
  background-color: #00bd8f !important;
}

.bg_acute-2 {
  background-color: #00f2b8 !important;
}

.bg_acute-3 {
  background-color: #4dffd4 !important;
}

.bg_acute-4 {
  background-color: #a6ffe9 !important;
}

.bg_acute-5 {
  background-color: #dbfff6 !important;
}

.bg_acute-n1 {
  background-color: #008263 !important;
}

.bg_acute-n2 {
  background-color: #006b51 !important;
}

.bg_acute-n3 {
  background-color: #004d3a !important;
}

.bg_acute-n4 {
  background-color: #002e23 !important;
}

.bg_acute-n5 {
  background-color: #001711 !important;
}

.c_acute {
  color: #009974 !important;
}

.c_acute-0 {
  color: #009974 !important;
}

.c_acute-1 {
  color: #00bd8f !important;
}

.c_acute-2 {
  color: #00f2b8 !important;
}

.c_acute-3 {
  color: #4dffd4 !important;
}

.c_acute-4 {
  color: #a6ffe9 !important;
}

.c_acute-5 {
  color: #dbfff6 !important;
}

.c_acute-n1 {
  color: #008263 !important;
}

.c_acute-n2 {
  color: #006b51 !important;
}

.c_acute-n3 {
  color: #004d3a !important;
}

.c_acute-n4 {
  color: #002e23 !important;
}

.c_acute-n5 {
  color: #001711 !important;
}

.br_acute {
  border-color: #009974 !important;
}

.br_acute-0 {
  border-color: #009974 !important;
}

.br_acute-1 {
  border-color: #00bd8f !important;
}

.br_acute-2 {
  border-color: #00f2b8 !important;
}

.br_acute-3 {
  border-color: #4dffd4 !important;
}

.br_acute-4 {
  border-color: #a6ffe9 !important;
}

.br_acute-5 {
  border-color: #dbfff6 !important;
}

.br_acute-n1 {
  border-color: #008263 !important;
}

.br_acute-n2 {
  border-color: #006b51 !important;
}

.br_acute-n3 {
  border-color: #004d3a !important;
}

.br_acute-n4 {
  border-color: #002e23 !important;
}

.br_acute-n5 {
  border-color: #001711 !important;
}

.bg_ischemic {
  background-color: #0f3e65 !important;
}

.bg_ischemic-0 {
  background-color: #0f3e65 !important;
}

.bg_ischemic-1 {
  background-color: #145387 !important;
}

.bg_ischemic-2 {
  background-color: #1c73bb !important;
}

.bg_ischemic-3 {
  background-color: #53a3e6 !important;
}

.bg_ischemic-4 {
  background-color: #a9d1f2 !important;
}

.bg_ischemic-5 {
  background-color: #ddedfa !important;
}

.bg_ischemic-n1 {
  background-color: #0d3556 !important;
}

.bg_ischemic-n2 {
  background-color: #0b2b47 !important;
}

.bg_ischemic-n3 {
  background-color: #081f33 !important;
}

.bg_ischemic-n4 {
  background-color: #05131e !important;
}

.bg_ischemic-n5 {
  background-color: #02090f !important;
}

.c_ischemic {
  color: #0f3e65 !important;
}

.c_ischemic-0 {
  color: #0f3e65 !important;
}

.c_ischemic-1 {
  color: #145387 !important;
}

.c_ischemic-2 {
  color: #1c73bb !important;
}

.c_ischemic-3 {
  color: #53a3e6 !important;
}

.c_ischemic-4 {
  color: #a9d1f2 !important;
}

.c_ischemic-5 {
  color: #ddedfa !important;
}

.c_ischemic-n1 {
  color: #0d3556 !important;
}

.c_ischemic-n2 {
  color: #0b2b47 !important;
}

.c_ischemic-n3 {
  color: #081f33 !important;
}

.c_ischemic-n4 {
  color: #05131e !important;
}

.c_ischemic-n5 {
  color: #02090f !important;
}

.br_ischemic {
  border-color: #0f3e65 !important;
}

.br_ischemic-0 {
  border-color: #0f3e65 !important;
}

.br_ischemic-1 {
  border-color: #145387 !important;
}

.br_ischemic-2 {
  border-color: #1c73bb !important;
}

.br_ischemic-3 {
  border-color: #53a3e6 !important;
}

.br_ischemic-4 {
  border-color: #a9d1f2 !important;
}

.br_ischemic-5 {
  border-color: #ddedfa !important;
}

.br_ischemic-n1 {
  border-color: #0d3556 !important;
}

.br_ischemic-n2 {
  border-color: #0b2b47 !important;
}

.br_ischemic-n3 {
  border-color: #081f33 !important;
}

.br_ischemic-n4 {
  border-color: #05131e !important;
}

.br_ischemic-n5 {
  border-color: #02090f !important;
}

.bg_special {
  background-color: #00b6f1 !important;
}

.bg_special-0 {
  background-color: #00b6f1 !important;
}

.bg_special-1 {
  background-color: #0dc4ff !important;
}

.bg_special-2 {
  background-color: #35ceff !important;
}

.bg_special-3 {
  background-color: #79deff !important;
}

.bg_special-4 {
  background-color: #bcefff !important;
}

.bg_special-5 {
  background-color: #e4f8ff !important;
}

.bg_special-n1 {
  background-color: #009bcd !important;
}

.bg_special-n2 {
  background-color: #007fa9 !important;
}

.bg_special-n3 {
  background-color: #005b79 !important;
}

.bg_special-n4 {
  background-color: #003748 !important;
}

.bg_special-n5 {
  background-color: #001b24 !important;
}

.c_special {
  color: #00b6f1 !important;
}

.c_special-0 {
  color: #00b6f1 !important;
}

.c_special-1 {
  color: #0dc4ff !important;
}

.c_special-2 {
  color: #35ceff !important;
}

.c_special-3 {
  color: #79deff !important;
}

.c_special-4 {
  color: #bcefff !important;
}

.c_special-5 {
  color: #e4f8ff !important;
}

.c_special-n1 {
  color: #009bcd !important;
}

.c_special-n2 {
  color: #007fa9 !important;
}

.c_special-n3 {
  color: #005b79 !important;
}

.c_special-n4 {
  color: #003748 !important;
}

.c_special-n5 {
  color: #001b24 !important;
}

.br_special {
  border-color: #00b6f1 !important;
}

.br_special-0 {
  border-color: #00b6f1 !important;
}

.br_special-1 {
  border-color: #0dc4ff !important;
}

.br_special-2 {
  border-color: #35ceff !important;
}

.br_special-3 {
  border-color: #79deff !important;
}

.br_special-4 {
  border-color: #bcefff !important;
}

.br_special-5 {
  border-color: #e4f8ff !important;
}

.br_special-n1 {
  border-color: #009bcd !important;
}

.br_special-n2 {
  border-color: #007fa9 !important;
}

.br_special-n3 {
  border-color: #005b79 !important;
}

.br_special-n4 {
  border-color: #003748 !important;
}

.br_special-n5 {
  border-color: #001b24 !important;
}

.bg_pandemic {
  background-color: #8e2582 !important;
}

.bg_pandemic-0 {
  background-color: #8e2582 !important;
}

.bg_pandemic-1 {
  background-color: #a82c9a !important;
}

.bg_pandemic-2 {
  background-color: #cc3abb !important;
}

.bg_pandemic-3 {
  background-color: #dd7cd2 !important;
}

.bg_pandemic-4 {
  background-color: #eebde8 !important;
}

.bg_pandemic-5 {
  background-color: #f8e5f6 !important;
}

.bg_pandemic-n1 {
  background-color: #791f6f !important;
}

.bg_pandemic-n2 {
  background-color: #631a5b !important;
}

.bg_pandemic-n3 {
  background-color: #471341 !important;
}

.bg_pandemic-n4 {
  background-color: #2b0b27 !important;
}

.bg_pandemic-n5 {
  background-color: #150614 !important;
}

.c_pandemic {
  color: #8e2582 !important;
}

.c_pandemic-0 {
  color: #8e2582 !important;
}

.c_pandemic-1 {
  color: #a82c9a !important;
}

.c_pandemic-2 {
  color: #cc3abb !important;
}

.c_pandemic-3 {
  color: #dd7cd2 !important;
}

.c_pandemic-4 {
  color: #eebde8 !important;
}

.c_pandemic-5 {
  color: #f8e5f6 !important;
}

.c_pandemic-n1 {
  color: #791f6f !important;
}

.c_pandemic-n2 {
  color: #631a5b !important;
}

.c_pandemic-n3 {
  color: #471341 !important;
}

.c_pandemic-n4 {
  color: #2b0b27 !important;
}

.c_pandemic-n5 {
  color: #150614 !important;
}

.br_pandemic {
  border-color: #8e2582 !important;
}

.br_pandemic-0 {
  border-color: #8e2582 !important;
}

.br_pandemic-1 {
  border-color: #a82c9a !important;
}

.br_pandemic-2 {
  border-color: #cc3abb !important;
}

.br_pandemic-3 {
  border-color: #dd7cd2 !important;
}

.br_pandemic-4 {
  border-color: #eebde8 !important;
}

.br_pandemic-5 {
  border-color: #f8e5f6 !important;
}

.br_pandemic-n1 {
  border-color: #791f6f !important;
}

.br_pandemic-n2 {
  border-color: #631a5b !important;
}

.br_pandemic-n3 {
  border-color: #471341 !important;
}

.br_pandemic-n4 {
  border-color: #2b0b27 !important;
}

.br_pandemic-n5 {
  border-color: #150614 !important;
}

/** SCSS DOC: __globalshame_uc.scss **/